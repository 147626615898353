import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueToastr2 from "vue-toastr-2";
import "@/utils/directives/directives";
import "vue-toastr-2/dist/vue-toastr-2.min.css";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
window.toastr = require("toastr");

Vue.use(VueToastr2, {
  closeButton: false,
  newestOnTop: true,
  progressBar: false,
  positionClass: "toast-top-right",
  preventDuplicates: true,
  onclick: null,
  showDuration: "300",
  hideDuration: "1000",
  timeOut: "5000",
  extendedTimeOut: "1000",
  showEasing: "swing",
  hideEasing: "linear",
  showMethod: "fadeIn",
  hideMethod: "fadeOut",
});
import "@/assets/fonts/SF-Pro-Text/style.css";
import VueMask from "v-mask";
import VueCarousel from "vue-carousel";
Vue.use(VueCarousel);
Vue.config.productionTip = false;
Vue.use(VueMask);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
