
import Vue, { PropType } from "vue";
import UiText from "@/ui/ui-text/UiText.vue";
import { ICategory } from "@/store/categories/state";

export default Vue.extend({
  name: "CategoriesItem",
  components: { UiText },
  props: {
    onItemClick: {
      type: Function,
    },
    data: {
      type: Object as PropType<ICategory>,
      default: () => ({}),
    },
  },
  computed: {
    getImage() {
      try {
        return this.data?.CategoryImage[0].Image.link;
      } catch (e) {
        return require("@/assets/img/no-images.jpeg");
      }
    },
  },
  methods: {
    onItemClickLocal() {
      this.$emit("onItemClick", this.data?.id);
    },
  },
});
