import { IAddressProfile, IProfile, IState } from "./state";
import { IError } from "@/common/types/errors";

export const types = {
  ON_LOAD_PROFILE_SUCCESS: "ON_LOAD_PROFILE_SUCCESS",
  ON_LOAD_PROFILE_ERROR: "ON_LOAD_PROFILE_ERROR",
  SET_PROFILE_FETCHING: "SET_PROFILE_FETCHING",
  ON_CREATE_ADDRESS_PROFILE_SUCCESS: "ON_CREATE_ADDRESS_PROFILE_SUCCESS",
  ON_CREATE_ADDRESS_PROFILE_ERROR: "ON_CREATE_ADDRESS_PROFILE_ERROR",
  SET_ADDRESS_PROFILE_FETCHING: "SET_ADDRESS_PROFILE_FETCHING",
};

export default {
  [types.ON_LOAD_PROFILE_SUCCESS](state: IState, payload: IProfile) {
    state.profileData = payload;
  },
  [types.ON_LOAD_PROFILE_ERROR](state: IState, errorsList: IError) {
    state.profileErrors = errorsList;
  },
  [types.SET_PROFILE_FETCHING](state: IState, isFetching: boolean) {
    state.isProfileFetching = isFetching;
  },
  [types.ON_CREATE_ADDRESS_PROFILE_SUCCESS](
    state: IState,
    payload: IAddressProfile
  ) {
    state.profileData?.AddressProfile?.push(payload);
  },
  [types.ON_CREATE_ADDRESS_PROFILE_ERROR](state: IState, errorsList: IError) {
    state.addressProfileErrors = errorsList;
  },
  [types.SET_ADDRESS_PROFILE_FETCHING](state: IState, isFetching: boolean) {
    state.isAddressProfileFetching = isFetching;
  },
};
