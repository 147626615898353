import { IProduct } from "@/store/products/state";

export interface IImage {
  id: number;
  link: string;
}

export interface IModificationsProductImage extends IImage {
  modificationsProductID: number;
  imageID: number;
  order: number;
}

export interface ICharacteristics {
  id: string;
  meta: {
    href: string;
    mediaType: string;
    type: string;
  };
  name: string;
  value: string;
}

export interface IModificationsProduct {
  id: number;
  inTransit: number;
  isView: boolean;
  name: string;
  price: number;
  productID: number;
  quantity: number;
  reserve: number;
  stock: number;
  stockID: string;
  vendorCode: string;
  characteristics: [ICharacteristics];
  colorRGB: string;
  product: IProduct;
  ModificationsProductImage: [IModificationsProductImage];
}

export interface IProductImage {
  id: number;
  productID: number;
  imageID: number;
  Image: IImage;
}

export interface IProductData {
  id: number;
  name: string;
  description: string;
  categoryID: string;
  vendorCode: string;
  stockID: string;
  isView: boolean;
  price: number;
  ProductImage: [IProductImage];
  ModificationsProduct: Array<IModificationsProduct>;
  saleType: "SALE" | "HIT";
  priceAfterDiscount: number;
}
export interface IProductDataPayload {
  data: IProductData;
  loadFromSearch: boolean;
}

export interface IState {
  productData: IProductData;
  isProductFetching: boolean;
  loadFromSearch: boolean;
  productErrors: object;
}
export default (): IState => ({
  productData: <IProductData>{},
  isProductFetching: false,
  loadFromSearch: false,
  productErrors: {},
});
