import { $BotHttp } from "@/utils";
import {
  ILoadProfilePayload,
  ICreateAddressProfilePayload,
} from "./ProfileServiceTypes";

export const loadProfile = (id: ILoadProfilePayload) =>
  $BotHttp.get(`profile/${id}`);

export const createAddress = (payload: ICreateAddressProfilePayload) =>
  $BotHttp.post(`address-profile/`, payload);
