
import Vue, { PropType } from "vue";
import { TIconName } from "./UiIconTypes";

export default Vue.extend({
  name: "UiIcon",
  props: {
    name: {
      type: String as PropType<TIconName>,
      required: true,
    },
    size: {
      type: [String, Number],
      default: "24",
    },
    color: {
      type: String,
      default: "#000000",
    },
  },
  computed: {
    iconStyle(): object {
      return {
        "--fontSize": `${this.size}px`,
        "--color": `${this.color}`,
      };
    },
  },
});
