
import Vue from "vue";

export default Vue.extend({
  name: "UiSpinner",
  props: {
    size: {
      type: String,
      default: "small",
    },
    type: {
      type: String,
      default: "default",
    },
  },
});
