
import Vue from "vue";
import UiIcon from "@/ui/ui-icon/UiIcon.vue";
import CategoriesItem from "@/components/categories/categories-item/CategoriesItem.vue";
import { mapGetters } from "vuex";
import { Constants } from "@/config";
export default Vue.extend({
  components: {
    UiIcon,
    CategoriesItem,
  },
  data() {
    let inputValue = "";

    return {
      inputValue,
    };
  },
  computed: {
    ...mapGetters({
      categoriesList: "categoriesList",
      categoriesPagination: "categoriesPagination",
      isCategoriesFetching: "isCategoriesFetching",
    }),
    getMask() {
      let mask = "";
      for (let i = 0; i < 50; i++) {
        mask += "#";
      }
      return mask;
    },
  },
  created() {
    this.loadCategories();
  },
  mounted() {
    window.addEventListener("scroll", this.scrollHandler);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.scrollHandler);
  },
  methods: {
    changeInput(event) {
      this.inputValue = /^[0-9]$/.test(event.target.value)
        ? event.target.value
        : this.inputValue;
    },
    enterInput() {
      this.$store.dispatch("loadOneProduct", {
        vendorCode: this.inputValue,
        loadFromSearch: true,
      });
    },
    loadCategories() {
      const payload = {
        limit: Constants.BASE_API_LIMIT,
        offset: 1,
      };
      this.$store.dispatch("loadCategories", payload);
    },
    loadMoreCategories() {
      const payload = {
        limit: this.categoriesPagination?.limit,
        offset: this.categoriesPagination?.nextOffset,
      };
      if (this.categoriesPagination?.nextOffset) {
        this.$store.dispatch("loadMoreCategories", payload);
      }
    },
    scrollHandler() {
      const watchContainer = document.querySelector(".categories-grid");
      const containerHeight = (watchContainer as any).offsetHeight;
      const isBottom =
        document.documentElement.scrollTop + window.innerHeight >=
        containerHeight;
      if (isBottom && !this.isCategoriesFetching) {
        this.loadMoreCategories();
      }
    },
    onItemClick(id: number) {
      if (id) {
        this.$router.push(`/products?category=${id}`);
      }
    },
  },
});
