
import UiIcon from "@/ui/ui-icon/UiIcon.vue";
import Vue from "vue";
import img1 from "@/assets/img/product.png";
import img2 from "@/assets/img/table.png";
import img3 from "@/assets/img/Foto.png";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { Carousel, Slide } from "vue-carousel";
import { mapGetters } from "vuex";
import { ICartItem } from "@/store/cart/state";
import { getOrderedSizes, numberSeparator } from "@/utils";
import { Constants } from "@/config";
import telegram from "@/common/telegram";
import ModalImg from "@/components/modals/modal-img/ModalImg.vue";

export default Vue.extend({
  components: {
    UiIcon,
    ModalImg,
  },
  data() {
    let isShowModalSizes = false;
    let imgLink = "";
    const leftSizes = [];
    const sliderItems = [
      { id: 1, img: img1 },
      { id: 2, img: img2 },
      { id: 3, img: img3 },
    ];
    return {
      selectedColorIndex: 0,
      selectedSizeIndex: 0,
      sliderItems,
      leftSizes,
      isShowModalSizes,
      imgLink,
    };
  },
  computed: {
    ...mapGetters({
      productData: "productData",
      parsedProductData: "parsedProductData",
      cartIdsItemsList: "cartIdsItemsList",
      cartItemsCounter: "cartItemsCounter",
      cartList: "cartList",
      profileID: "profileID",
      isProductFetching: "isProductFetching",
      loadFromSearch: "loadFromSearch",
      cartIdsProductList: "cartIdsProductList",
    }),
    sizeList() {
      return (
        this.parsedProductData[this.selectedColor?.color.value] || []
      ).map((item) => {
        return {
          ...item,
          size: {
            ...item.size,
            counter: 0,
          },
        };
      });
    },
    sortedSizeList() {
      return getOrderedSizes([...this.sizeList]);
    },
    colorList() {
      return Object.keys(this.parsedProductData).map(
        (item) => this.parsedProductData[item][0]
      );
    },
    paramsList() {
      return (
        (this.parsedProductData[this.selectedColor?.color.value] || []).find(
          (item) => item.size.value === this.selectedSize.size.value
        ) || {}
      ).paramsList;
    },
    selectedColor() {
      return this.colorList[this.selectedColorIndex];
    },
    selectedSize() {
      return this.sortedSizeList[this.selectedSizeIndex];
    },
    imagesList() {
      // if (this.productData?.ProductImage?.length) {
      // const preview = this.productData.ProductImage[0];
      const sizesScreen =
        this.productData.ProductImage.length > 1
          ? this.productData.ProductImage[
              this.productData.ProductImage.length - 1
            ]
          : {};
      if (this?.productData?.ModificationsProduct?.length > 0) {
        const modificationsImages = (this.selectedSize || {})
          .ModificationsProductImage;
        // if (Object.keys(preview).length && Object.keys(sizesScreen).length) {
        //   return [preview, ...modificationsImages, sizesScreen];
        // }
        // if (Object.keys(preview).length && !Object.keys(sizesScreen).length) {
        //   return [preview, ...modificationsImages];
        // }
        if (Object.keys(sizesScreen).length) {
          return [...modificationsImages, sizesScreen];
        }
        return [...modificationsImages];
      }
      return [];
      // } else {
      //   return [];
      // }
    },
  },
  created() {
    const vendorCode = this.$route.query.vendorCode;
    if (!vendorCode) {
      this.$router.push("/categories");
    } else {
      if (this.productData.vendorCode !== vendorCode) {
        this.$store.dispatch("loadOneProduct", {
          vendorCode: vendorCode,
          loadFromSearch: false,
        });
      }
    }
  },
  methods: {
    onNextSlide() {
      const current = this.$refs.carouselRef.currentPage;
      const nextPage = this.imagesList.length - 1 === current ? 0 : current + 1;
      this.$refs.carouselRef.goToPage(nextPage, "pagination");
    },
    onPrevSlide() {
      const current = this.$refs.carouselRef.currentPage;
      const prevPage = current === 0 ? this.imagesList.length - 1 : current - 1;
      this.$refs.carouselRef.goToPage(prevPage, "pagination");
    },
    copyLink() {
      this.$toastr.success("Скопировано");
    },

    onShareClick() {
      // it works only in telegram
      if (navigator.share !== undefined) {
        navigator.share({
          title: this.productData.name,
          url: `https://t.me/${Constants.VUE_APP_BOT_NAME}?start=product${this.$route.query.vendorCode}`,
        });
      }
    },
    numberSeparator(value: number) {
      return numberSeparator(value);
    },
    checkProductInCart(id: number) {
      return !!this.cartIdsItemsList.find((cartId: number) => cartId === id);
    },

    getAvailableAmount(id: number) {
      return this.productData.ModificationsProduct.find(
        (product) => product.id === id
      ).stock;
    },
    getProductCountInCart(id: number) {
      return (
        this.cartList.find(
          (cart: ICartItem) => cart.ModificationsProductID === id
        ).count || 0
      );
    },
    onSizeClick(index: number, id: number) {
      if (id) {
        this.onAddClick(id, 0, index);
      }
    },
    onColorClick(index: number) {
      this.selectedColorIndex = index;
      this.selectedSizeIndex = 0;
      this.$refs.carouselRef.goToPage(0, "pagination");
    },
    onAddClick(id: number, count: number, index: number) {
      this.selectedSizeIndex = index;
      const available = this.getAvailableAmount(id);
      if (available === count) {
        this.$toastr.warning(`К заказу доступно только ${available}шт`);
      } else {
        this.$store.dispatch("updateCart", { id, count: count + 1 });
      }
    },
    onRemoveClick(id: number, count: number, index: number) {
      this.selectedSizeIndex = index;
      const newCount = count - 1;
      if (newCount === 0) {
        const cartItemID = this.cartList.find(
          (item: ICartItem) => item.ModificationsProductID === id
        ).id;
        this.$store.dispatch("removeItemInCart", cartItemID);
      } else {
        this.$store.dispatch("updateCart", { id, count: newCount });
      }
    },
    checkCurrentProductInCart(productId: number) {
      return !!this.cartIdsProductList.find(
        (cartId: number) => cartId === productId
      );
    },
    toggleModal(imgSrc = "") {
      this.imgLink = imgSrc;
      this.isShowModalSizes = !this.isShowModalSizes;
    },
  },
});
