import { IPagination } from "@/common/types/pagination";
import { Constants } from "@/config";
import { IProduct } from "@/store/products/state";

export interface IImage {
  id: number;
  link: string;
}

export interface ICategoryImage {
  id: number;
  categoryID: number;
  imageID: number;
  Image: IImage;
}

export interface ICategory {
  id: number;
  name: string;
  postalCode: string;
  stockID: string;
  isView: boolean;
  CategoryImage: [ICategoryImage];
  Products: Array<IProduct>;
}

export interface ICategoriesData {
  list: Array<ICategory>;
  pagination: IPagination;
}

export interface IState {
  categoriesData: ICategoriesData;
  isCategoriesFetching: boolean;
  categoriesErrors: object;
}
export default (): IState => ({
  categoriesData: <ICategoriesData>{
    list: [],
    pagination: <IPagination>{
      limit: Constants.BASE_API_LIMIT,
      nextOffset: 1,
    },
  },
  isCategoriesFetching: false,
  categoriesErrors: {},
});
