
import Vue, { PropType } from "vue";
import { numberSeparator } from "@/utils";
import { ICartItem } from "@/store/cart/state";
import { Constants } from "@/config";
import UiIcon from "@/ui/ui-icon/UiIcon.vue";
import { mapGetters } from "vuex";

export default Vue.extend({
  name: "BasketItem",
  components: { UiIcon },
  props: {
    data: {
      type: Object as PropType<ICartItem>,
      default: () => ({}),
    },
    onItemClick: {
      type: Function,
    },
  },
  computed: {
    modificationData() {
      return this.data.ModificationsProduct;
    },
    getSize() {
      return (
        this.modificationData.characteristics.find(
          (item) => item.id === Constants.SIZE_CODE
        )?.value || "ONE SIZE"
      );
    },
    getColor() {
      return (
        this.modificationData.characteristics.find(
          (item) => item.id === Constants.COLORS_CODE
        )?.value || "Не указан"
      );
    },
    getImage() {
      try {
        return this.modificationData?.ModificationsProductImage[0].Image.link;
      } catch (e) {
        return require("@/assets/img/no-images.jpeg");
      }
    },
  },
  methods: {
    numberSeparator(value: number) {
      return numberSeparator(value);
    },
    onItemClickLocal() {
      this.$emit("onItemClick", this.data);
    },
    onRemoveClick(id: number, count: number) {
      const newCount = count - 1;
      if (newCount === 0) {
        this.$store.dispatch("removeItemInCart", this.data.id);
      } else {
        this.$store.dispatch("updateCart", { id, count: newCount });
      }
    },
    getAvailableAmount() {
      return this.modificationData.stock;
    },
    onAddClick(id: number, count: number) {
      const available = this.getAvailableAmount(id);
      if (available === count) {
        this.$toastr.warning(`К заказу доступно только ${available}шт`);
      } else {
        this.$store.dispatch("updateCart", { id, count: count + 1 });
      }
    },
  },
});
