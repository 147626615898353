import { ICartData, IState } from "./state";
import { IError } from "@/common/types/errors";

export const types = {
  ON_LOAD_CART_SUCCESS: "ON_LOAD_CART_SUCCESS",
  ON_LOAD_CART_ERROR: "ON_LOAD_CART_ERROR",
  SET_CART_FETCHING: "SET_CART_FETCHING",
  ADD_TO_CART: "ADD_TO_CART",
  REMOVE_FROM_CART: "REMOVE_FROM_CART",
};

export default {
  [types.ON_LOAD_CART_SUCCESS](state: IState, payload: ICartData) {
    state.cartData = payload;
  },
  [types.ON_LOAD_CART_ERROR](state: IState, errorsList: IError) {
    state.cartErrors = errorsList;
  },
  [types.SET_CART_FETCHING](state: IState, isFetching: boolean) {
    state.isCartFetching = isFetching;
  },
};
