
import Vue from "vue";
import BasketItem from "@/components/basket/basket-item/BasketItem.vue";
import UiIcon from "@/ui/ui-icon/UiIcon.vue";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { SwipeList, SwipeOut } from "vue-swipe-actions";
import "vue-swipe-actions/dist/vue-swipe-actions.css";
import { mapGetters } from "vuex";
import { numberSeparator } from "@/utils";
import { ICreateOrderPayload } from "@/services/OrderService/OrderServiceTypes";
import { ICreateAddressProfilePayload } from "@/services/ProfileService/ProfileServiceTypes";

export default Vue.extend({
  components: {
    BasketItem,
    SwipeList,
    // eslint-disable-next-line vue/no-unused-components
    SwipeOut,
    UiIcon,
  },
  data() {
    return {
      activeShipmentIndex: 0,
      activeSubShipmentIndex: 0,
      selectedAddress: {},
      isAddNewAddressWasClicked: false,
      shipmentList: [
        {
          value: "CDEK",
          name: "CDEK",
          items: [
            { value: "TODOOR", name: "До двери" },
            { value: "TOSTOCK", name: "До склада CDEK" },
          ],
          comment:
            "В поле комментарий укажите ближайший к вам пункт выдачи заказов",
        },
        {
          value: "POSTRUSSIA",
          name: "Почта России",
          items: [{ value: "TOSTOCK", name: "В отделение" }],
          comment:
            "В поле комментарий укажите индекс ближайшего отделения почты России ",
        },
      ],
      name: "",
      surname: "",
      patronymic: "",
      phone: "",
      town: "",
      postalCode: "",
      address: "",
      country: "Россия",
      comment: "",
    };
  },
  computed: {
    ...mapGetters({
      cartList: "cartList",
      profileID: "profileID",
      addressList: "addressList",
    }),
    currentShipment() {
      return this.shipmentList?.[this.activeShipmentIndex];
    },
    currentSubShipment() {
      return this.shipmentList?.[this.activeShipmentIndex]?.items[
        this.activeSubShipmentIndex
      ];
    },
    // не удалять - используется в watch
    isOrderButtonDisabled() {
      return (
        (!this.surname.length ||
          !this.name.length ||
          !this.phone.length ||
          !this.town.length ||
          !this.postalCode.length ||
          !this.cartList.length ||
          !this.country.length ||
          this.isPhoneError ||
          this.isPostalCodeError ||
          !this.address.length) &&
        !this.isAddressSelected
      );
    },
    isPostalCodeError() {
      return this.postalCode.length > 0 && this.postalCode.length < 6;
    },
    isPhoneError() {
      if (this.isRussia) {
        return this.phone.length > 0 && this.phone.length < 18;
      } else {
        return false;
      }
    },
    isAddressSelected() {
      return Object.keys(this.selectedAddress).length;
    },
    isRussia() {
      return this.country.toUpperCase() === "РОССИЯ";
    },
    getPhoneMask() {
      if (this.isRussia) {
        return "+7 (###) ###-##-##";
      } else return "";
    },
    getTextDelivery() {
      if (
        this.activeShipmentIndex === 0 &&
        this.shipmentList[this.activeShipmentIndex].items?.[
          this.activeSubShipmentIndex
        ].value === "TOSTOCK"
      ) {
        return this.shipmentList[this.activeShipmentIndex].comment;
      } else if (this.activeShipmentIndex === 1) {
        return this.shipmentList[this.activeShipmentIndex].comment;
      }
      return "";
    },
  },
  watch: {
    isOrderButtonDisabled(newVal) {
      this.$root.$emit("setMainTgButtonDisabled", newVal);
    },
  },
  mounted() {
    this.$root.$on("onCreateOrder", () => {
      this.onPayOrderClick();
    });
    setTimeout(() => {
      this.showPreview();
    }, 200);
  },
  beforeDestroy() {
    this.$root.$off("onCreateOrder");
  },
  methods: {
    setShipmentIndex(index: number) {
      this.activeShipmentIndex = index;
      this.activeSubShipmentIndex = 0;
    },
    setSubShipmentIndex(index: number) {
      this.activeSubShipmentIndex = index;
    },
    deleteItem(id: string): void {
      this.$store.dispatch("removeItemInCart", id);
    },
    numberSeparator(value: number) {
      return numberSeparator(value);
    },
    onItemClick(item) {
      this.$router.push(
        `/product?vendorCode=${item.ModificationsProduct.product.vendorCode}`
      );
    },
    selectAddress(address) {
      this.selectedAddress = address;
      this.isAddNewAddressWasClicked = false;
    },
    onAddNewAddressClick() {
      this.selectAddress({});
      this.isAddNewAddressWasClicked = true;
    },
    async onPayOrderClick() {
      if (!this.isAddressSelected) {
        const { id } = await this.createAddressProfile();
        await this.createOrder(id);
      } else {
        await this.createOrder(this.selectedAddress.id);
      }
    },
    async createAddressProfile() {
      const payload: ICreateAddressProfilePayload = {
        country: this.country,
        phone: this.phone,
        postalCode: +this.postalCode,
        town: this.town,
        address: this.address,
        name: this.name,
        surname: this.surname,
        patronymic: this.patronymic,
        profileID: this.profileID,
      };
      return await this.$store.dispatch("createAddressProfile", payload);
    },
    async createOrder(addressProfileID) {
      const payload: ICreateOrderPayload = {
        profileID: this.profileID,
        deliveryType: this.currentShipment.value,
        deliveryTo: this.currentSubShipment.value,
        comment: this.comment,
        addressProfileID,
      };
      return await this.$store.dispatch("createOrder", payload);
    },
    onPhoneInput(el) {
      let val = el.target.value;
      if ((val === "7" || val === "8") && this.isRussia) {
        val = "+7";
      }
      this.phone = val;
    },
    showPreview() {
      if (this.cartList.length) {
        this.$refs.cartListRef.revealRight(0);
        setTimeout(() => {
          this.$refs.cartListRef.closeActions(0);
        }, 400);
      }
    },
    renderAddress(address) {
      return `${address.country}, ${address.postalCode}, г. ${address.town}, ${
        address.address
      }. ${address.surname} ${address.name} ${address.patronymic || ""}`;
    },
  },
});
