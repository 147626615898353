import { IState } from "./state";

export default {
  cartList: (state: IState) => {
    const sortedCartList = state.cartData?.CartItems?.sort(
      (a, b) => a.id - b.id
    );
    return sortedCartList || [];
  },
  cartItemSum: (state: IState) =>
    state.cartData.CartItems.reduce(
      (acc, item) => acc + item.count * (item.ModificationsProduct.price / 100),
      0
    ),
  cartIdsItemsList: (state: IState) =>
    (state.cartData?.CartItems || []).map(
      (item) => item.ModificationsProductID
    ),
  cartIdsProductList: (state: IState) =>
    (state.cartData?.CartItems || []).map(
      (item) => item.ModificationsProduct.productID
    ),
  cartItemsCounter: (state: IState) =>
    (state.cartData?.CartItems || []).reduce(
      (acc, item) => acc + item.count,
      0
    ),
  isCartFetching: (state: IState) => state.isCartFetching,
};
