import { $BotHttp } from "@/utils";
import { ILoadCartPayload, IUpdateCartApiPayload } from "./CartServiceTypes";

export const loadCart = (params: ILoadCartPayload) =>
  $BotHttp.get("cart", params);

export const updateCart = (body: IUpdateCartApiPayload) =>
  $BotHttp.post("cart", body);

export const removeItemInCart = (id: number) => $BotHttp.delete(`cart/${id}`);
