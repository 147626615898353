import { Commit } from "vuex";
import { ProductsService } from "@/services";
import { types } from "./mutations";
import { IProductOrderPayload } from "@/services/ProductsService/ProductsServiceTypes";
import router from "@/router";

export default {
  async loadOneProduct(
    { commit }: { commit: Commit },
    payload: IProductOrderPayload
  ) {
    commit(types.SET_PRODUCT_FETCHING, true);
    try {
      const res = await ProductsService.loadOneProduct(payload.vendorCode);
      if (Object.keys(res.data).length && payload.loadFromSearch) {
        router.push(`/product?vendorCode=${res.data.vendorCode}`);
      }
      if (Object.keys(res.data).length == 0 && payload.loadFromSearch) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        window.toastr.warning("Неверный артикул");
      }
      commit(types.ON_LOAD_PRODUCT_SUCCESS, {
        data: res.data,
        loadFromSearch: payload.loadFromSearch,
      });
    } catch (e) {
      commit(types.ON_LOAD_PRODUCT_ERROR, e);
    }
    commit(types.SET_PRODUCT_FETCHING, false);
  },
};
