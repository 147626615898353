import { Commit } from "vuex";
import { CartService } from "@/services";
import { types } from "./mutations";
import {
  ILoadCartPayload,
  IUpdateCartActionPayload,
  IUpdateCartApiPayload,
} from "@/services/CartService/CartServiceTypes";
import { ICartItem } from "@/store/cart/state";

export default {
  async loadCart({ commit }: { commit: Commit }, payload: ILoadCartPayload) {
    commit(types.SET_CART_FETCHING, true);
    try {
      const res = await CartService.loadCart(payload);
      commit(types.ON_LOAD_CART_SUCCESS, res.data);
    } catch (e) {
      commit(types.ON_LOAD_CART_ERROR, e);
    }
    commit(types.SET_CART_FETCHING, false);
  },
  async updateCart(
    { commit, getters }: { commit: Commit; getters: any },
    payload: IUpdateCartActionPayload
  ) {
    try {
      const apiPayload: IUpdateCartApiPayload = {
        profileID: +getters.profileID,
        ModificationsProducts: [{ id: +payload.id, count: +payload.count }],
      };
      const res = await CartService.updateCart(apiPayload);
      commit(types.ON_LOAD_CART_SUCCESS, res.data);
    } catch (e) {
      commit(types.ON_LOAD_CART_ERROR, e);
    }
  },
  async removeItemInCart(
    { commit, state }: { commit: Commit; state: any },
    id: number
  ) {
    try {
      const { data } = await CartService.removeItemInCart(id);
      const newCartItems = state.cartData?.CartItems.filter(
        (item: ICartItem) =>
          item.ModificationsProductID !== data.ModificationsProductID
      );
      const stateCopy = { ...state.cartData, CartItems: newCartItems };
      commit(types.ON_LOAD_CART_SUCCESS, stateCopy);
    } catch (e) {
      commit(types.ON_LOAD_CART_ERROR, e);
    }
  },
};
