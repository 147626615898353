
import Vue, { PropType } from "vue";
import { IProduct } from "@/store/products/state";
import { numberSeparator } from "@/utils";
import { mapGetters } from "vuex";

export default Vue.extend({
  name: "ProductsItem",
  props: {
    onItemClick: {
      type: Function,
    },
    onButtonClick: {
      type: Function,
    },
    data: {
      type: Object as PropType<IProduct>,
      default: () => ({}),
    },
  },
  data() {
    return {
      // rating: 3,
      inBasket: false,
    };
  },
  computed: {
    ...mapGetters({
      cartIdsProductList: "cartIdsProductList",
    }),
    // getIcon(): string {
    //   let result = "";
    //   if (this.icon === "sun") {
    //     result = "sun";
    //   } else if (this.icon === "cold") {
    //     result = "snowflake";
    //   } else if (this.icon === "flower") {
    //     result = "Shape";
    //   }
    //   return result;
    // },
    getImage() {
      try {
        return this.data?.ProductImage[0].Image.link;
      } catch (e) {
        return require("@/assets/img/no-images.jpeg");
      }
    },
  },
  methods: {
    numberSeparator(value: number) {
      return numberSeparator(value);
    },
    onItemClickLocal() {
      this.$emit("onItemClick", this.data?.vendorCode);
    },
    onButtonClickLocal(event) {
      event.stopPropagation();
      if (this.checkProductInCart(this.data.id)) {
        this.$router.push("/cart");
      } else {
        this.$emit("onButtonClick", this.data?.vendorCode);
      }
    },
    checkProductInCart(id: number) {
      return !!this.cartIdsProductList.find((cartId: number) => cartId === id);
    },
  },
});
