import Vue from "vue";
import Vuex from "vuex";

import categories from "./categories";
import products from "./products";
import product from "./product";
import cart from "./cart";
import profile from "./profile";
import order from "./order";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: { categories, products, product, cart, profile, order },
});
