export type WindowType = any;

declare const window: WindowType;

export default class telegram {
  private static _instance: telegram;
  context;
  private constructor() {
    window.Telegram.WebApp.ready();
    this.context = window.Telegram.WebApp;
  }

  public static get Instance() {
    return this._instance || (this._instance = new this());
  }
}
