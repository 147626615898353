import { IState } from "./state";
import { IError } from "@/common/types/errors";

export const types = {
  ON_CREATE_ORDER_SUCCESS: "ON_CREATE_ORDER_SUCCESS",
  ON_CREATE_ORDER_ERROR: "ON_CREATE_ORDER_ERROR",
  SET_CREATE_ORDER_FETCHING: "SET_CREATE_ORDER_FETCHING",
};

export default {
  [types.ON_CREATE_ORDER_ERROR](state: IState, errorsList: IError) {
    state.createOrderErrors = errorsList;
  },
  [types.SET_CREATE_ORDER_FETCHING](state: IState, isFetching: boolean) {
    state.isCreateOrderFetching = isFetching;
  },
};
