/**
 * @description Добавляет пробелы числу
 * @param {number} value - значение которое надо преобразовать
 */
const numberSeparator = (value: number) => {
  const decimals = value.toString().split(".")[1];
  return `${Math.floor(value)
    .toString()
    .split("")
    .reverse()
    .join("")
    .replace(/\d\d\d/g, "$& ")
    .split("")
    .reverse()
    .join("")}${decimals ? `.${decimals}` : ""}`;
};

export default numberSeparator;
