import { ICharacteristics, IModificationsProduct, IState } from "./state";
import { Constants } from "@/config";

interface IModificationsProductLocal extends IModificationsProduct {
  color: {
    value: string;
    name: string;
    id: string;
  };
  size: {
    value: string;
    name: string;
    id: string;
  };
}

export default {
  productData: (state: IState) => state.productData,
  isProductFetching: (state: IState) => state.isProductFetching,
  loadFromSearch: (state: IState) => state.loadFromSearch,
  parsedProductData: (state: IState) => {
    const tmp = (state.productData.ModificationsProduct || []).map(
      (item: IModificationsProduct) => {
        return {
          ...item,
          color:
            item.characteristics.find(
              (subitem: ICharacteristics) =>
                subitem.id === Constants.COLORS_CODE
            ) || {},
          size: item.characteristics.find(
            (subitem: ICharacteristics) => subitem.id === Constants.SIZE_CODE
          ) || {
            counter: 0,
            value: "ONE SIZE",
          },
          paramsList:
            item.characteristics.filter(
              (subitem: ICharacteristics) =>
                subitem.id !== Constants.SIZE_CODE &&
                subitem.id !== Constants.COLORS_CODE
            ) || [],
        };
      }
    );
    // группируем по цветам
    const res = {};
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    tmp.forEach((item: IModificationsProductLocal) => {
      if (!res[item.color.value]) {
        res[item.color.value] = [];
      }
      res[item.color.value].push(item);
    });
    return res;
  },
};
