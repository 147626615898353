
import Vue, { PropType } from "vue";

enum uiText {
  "regular",
  "medium",
  "bold",
}

type TUiText = keyof typeof uiText;

export default Vue.extend({
  name: "UiText",
  props: {
    textContent: {
      type: String,
      required: true,
    },
    textType: {
      type: String as PropType<TUiText>,
      required: true,
    },
  },
});
