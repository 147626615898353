import { IProductsData, IState } from "./state";
import { IError } from "@/common/types/errors";

export const types = {
  ON_LOAD_PRODUCTS_SUCCESS: "ON_LOAD_PRODUCTS_SUCCESS",
  ON_LOAD_PRODUCTS_ERROR: "ON_LOAD_PRODUCTS_ERROR",
  ON_LOAD_MORE_PRODUCTS_SUCCESS: "ON_LOAD_MORE_PRODUCTS_SUCCESS",
  ON_LOAD_MORE_PRODUCTS_ERROR: "ON_LOAD_MORE_PRODUCTS_ERROR",
  SET_PRODUCTS_FETCHING: "SET_PRODUCTS_FETCHING",
  SET_PRODUCTS_FETCHING_MORE: "SET_PRODUCTS_FETCHING_MORE",
  SET_CATEGORY: "SET_CATEGORY",
};

export default {
  [types.ON_LOAD_PRODUCTS_SUCCESS](state: IState, payload: IProductsData) {
    const { pagination, list } = payload;
    state.productsData = {
      list,
      pagination,
    };
  },
  [types.ON_LOAD_PRODUCTS_ERROR](state: IState, errorsList: IError) {
    state.productsErrors = errorsList;
  },
  [types.ON_LOAD_MORE_PRODUCTS_SUCCESS](state: IState, payload: IProductsData) {
    const { pagination, list } = payload;
    const newList = [...state.productsData.list, ...list];
    state.productsData = {
      list: newList,
      pagination,
    };
  },
  [types.ON_LOAD_MORE_PRODUCTS_ERROR](state: IState, errorsList: IError) {
    state.productsErrors = errorsList;
  },
  [types.SET_PRODUCTS_FETCHING](state: IState, isFetching: boolean) {
    state.isProductsFetching = isFetching;
  },
  [types.SET_PRODUCTS_FETCHING_MORE](state: IState, isFetching: boolean) {
    state.isProductsFetchingMore = isFetching;
  },
  [types.SET_CATEGORY](state: IState, numberCategory: string) {
    state.numberCategory = numberCategory;
  },
};
