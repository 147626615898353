
import EX_$Theme from "@/common/theme/theme";
import Vue from "vue";
import telegram from "@/common/telegram";
import { Constants } from "@/config";
import { mapGetters } from "vuex";
import { numberSeparator } from "@/utils";
export default Vue.extend({
  data() {
    return {
      EX_$Theme,
    };
  },
  computed: {
    getTheme(): string {
      return EX_$Theme.currentTheme;
    },
    ...mapGetters({
      cartItemsCounter: "cartItemsCounter",
      cartItemSum: "cartItemSum",
    }),
  },
  watch: {
    cartItemsCounter(newLength) {
      this.checkMainTgButtonText();
      this.toggleMainTgButtonShowing(!!newLength);
    },
    $route() {
      const tg = telegram.Instance;
      tg.context.BackButton.isVisible = this.$route.name !== "categories";
      this.checkMainTgButtonText();
      const isButtonDisabled = this.$route.name === "cart";
      this.toggleMainTgButtonDisabled(isButtonDisabled);
    },
  },
  created() {
    let currentTgTheme = "default";
    if (!this.$route.query.myCat) {
      const tg = telegram.Instance;
      let userTelegramID = JSON.stringify(
        tg?.context?.initDataUnsafe?.user?.id
      );
      tg?.context?.expand?.();
      if (userTelegramID) {
        this.$store.dispatch("loadProfile", userTelegramID);
      } else {
        window.location.replace(`https://t.me/${Constants.VUE_APP_BOT_NAME}`);
        return false;
      }
      tg.context.MainButton.onClick(() => {
        if (this.$route.name === "cart") {
          this.$root.$emit("onCreateOrder");
        } else {
          this.$router.push("/cart");
        }
      });
      tg.context.BackButton.onClick(() => {
        this.$router.back();
      });
      currentTgTheme = tg?.context.colorScheme === "dark" ? "dark" : "default";
    }
    this.EX_$Theme.set(currentTgTheme);
    this.toggleMainTgButtonDisabled(this.$route.name === "cart");
    this.$root.$on("setMainTgButtonDisabled", (isDisabled) => {
      this.toggleMainTgButtonDisabled(isDisabled);
    });
  },
  beforeDestroy() {
    this.$root.$off("setMainTgButtonDisabled");
  },
  methods: {
    toggleMainTgButtonShowing(isShow: boolean) {
      const tg = telegram.Instance;
      if (isShow) {
        tg.context.MainButton.show();
      } else {
        tg.context.MainButton.hide();
      }
    },
    toggleMainTgButtonDisabled(isDisabled: boolean) {
      const tg = telegram.Instance;
      if (isDisabled) {
        this.setMainTgButtonBackgroundColor(false);
        tg.context.MainButton.disable();
      } else {
        this.setMainTgButtonBackgroundColor(true);
        tg.context.MainButton.enable();
      }
    },
    checkMainTgButtonText() {
      const tg = telegram.Instance;
      if (this.$route.name === "cart") {
        tg.context.MainButton.setText(
          `Оплатить ${numberSeparator(this.cartItemSum)} P`
        );
      } else {
        tg.context.MainButton.setText(
          `Посмотреть корзину (${this.cartItemsCounter})`
        );
      }
    },
    setMainTgButtonBackgroundColor(isActive = true) {
      const tg = telegram.Instance;
      tg.context.MainButton.color = isActive ? "#00BB34" : "#839D87";
    },
  },
});
