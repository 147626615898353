const SIZE_ORDER_LIST = [
  "ONE SIZE",
  "XXS",
  "XS",
  "XS-S",
  "S",
  "S-M",
  "M",
  "M-L",
  "L",
  "L-XL",
  "XL",
  "2XL",
  "XXL",
  "3XL",
  "XXXL",
  "4XL",
  "XXXL",
  "5XL",
  "XXXXXL",
  "6XL",
  "XXXXXXL",
];

const getOrderedSizes = (array) =>
  array.sort((a, b) => {
    a = a.size.value.toUpperCase();
    b = b.size.value.toUpperCase();

    let nra = parseInt(a);
    let nrb = parseInt(b);

    if (SIZE_ORDER_LIST.indexOf(a) != -1) nra = NaN;
    if (SIZE_ORDER_LIST.indexOf(b) != -1) nrb = NaN;

    if (nrb === 0) return 1;
    if ((nra && !nrb) || nra === 0) return -1;
    if (!nra && nrb) return 1;
    if (nra && nrb) {
      if (nra == nrb) {
        return a
          .substr(("" + nra).length)
          .localeCompare(a.substr(("" + nra).length));
      } else {
        return nra - nrb;
      }
    } else {
      return SIZE_ORDER_LIST.indexOf(a) - SIZE_ORDER_LIST.indexOf(b);
    }
  });

export { getOrderedSizes };
