import { ICategoriesData, IState } from "./state";
import { IError } from "@/common/types/errors";

export const types = {
  ON_LOAD_CATEGORIES_SUCCESS: "ON_LOAD_CATEGORIES_SUCCESS",
  ON_LOAD_CATEGORIES_ERROR: "ON_LOAD_CATEGORIES_ERROR",
  ON_LOAD_MORE_CATEGORIES_SUCCESS: "ON_LOAD_MORE_CATEGORIES_SUCCESS",
  ON_LOAD_MORE_CATEGORIES_ERROR: "ON_LOAD_MORE_CATEGORIES_ERROR",
  SET_CATEGORIES_FETCHING: "SET_CATEGORIES_FETCHING",
};

export default {
  [types.ON_LOAD_CATEGORIES_SUCCESS](state: IState, payload: ICategoriesData) {
    const { pagination, list } = payload;
    state.categoriesData = {
      list,
      pagination,
    };
  },
  [types.ON_LOAD_CATEGORIES_ERROR](state: IState, errorsList: IError) {
    state.categoriesErrors = errorsList;
  },
  [types.ON_LOAD_MORE_CATEGORIES_SUCCESS](
    state: IState,
    payload: ICategoriesData
  ) {
    const { pagination, list } = payload;
    const newList = [...state.categoriesData.list, ...list];
    state.categoriesData = {
      list: newList,
      pagination,
    };
  },
  [types.ON_LOAD_MORE_CATEGORIES_ERROR](state: IState, errorsList: IError) {
    state.categoriesErrors = errorsList;
  },
  [types.SET_CATEGORIES_FETCHING](state: IState, isFetching: boolean) {
    state.isCategoriesFetching = isFetching;
  },
};
