
import Vue from "vue";
export default Vue.extend({
  name: "ModalImg",

  props: {
    isShowModalImg: {
      type: Boolean,
      required: true,
    },
    link: {
      type: String,
      required: true,
    },
  },
  data() {
    return { selectedColorIndex: 0, selectedSizeIndex: 0 };
  },
  methods: {
    toggleModal(): void {
      this.$emit("toggleModal");
    },
  },
});
