import { $HttpClient } from "@/utils";
import { Domains } from "@/config";

const $BotHttp = new $HttpClient({
  _baseURL: Domains.apiDomain,
});

$BotHttp.instance.interceptors.response.use(
  async (response) => {
    return response;
  },
  async (error) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.toastr.error(error.response.data.message[0]);
    return error;
  }
);

export default $BotHttp;
