import { Commit } from "vuex";
import { ProfileService } from "@/services";
import { types as CartTypes } from "../cart/mutations";
import { types } from "./mutations";

import {
  ICreateAddressProfilePayload,
  ILoadProfilePayload,
} from "@/services/ProfileService/ProfileServiceTypes";

export default {
  async loadProfile({ commit }: { commit: Commit }, id: ILoadProfilePayload) {
    commit(types.SET_PROFILE_FETCHING, true);
    try {
      const res = await ProfileService.loadProfile(id);
      const cartData = res.data.Cart[0];
      delete res.data.Cart;
      const profileData = res.data;
      commit(CartTypes.ON_LOAD_CART_SUCCESS, cartData, { root: true });
      commit(types.ON_LOAD_PROFILE_SUCCESS, profileData);
    } catch (e) {
      commit(types.ON_LOAD_PROFILE_ERROR, e);
    }
    commit(types.SET_PROFILE_FETCHING, false);
  },
  async createAddressProfile(
    { commit }: { commit: Commit },
    payload: ICreateAddressProfilePayload
  ) {
    let res = {};
    commit(types.SET_ADDRESS_PROFILE_FETCHING, true);
    try {
      const { data } = await ProfileService.createAddress(payload);
      commit(types.ON_CREATE_ADDRESS_PROFILE_SUCCESS, data);
      res = data;
    } catch (e) {
      commit(types.ON_CREATE_ADDRESS_PROFILE_ERROR, e);
    }
    commit(types.SET_ADDRESS_PROFILE_FETCHING, false);
    return res;
  },
};
