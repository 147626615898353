import actions from "./actions";
import state from "./state";
import mutations from "./mutations";
import getters from "./getters";

const products = {
  namespace: true,
  state,
  actions,
  mutations,
  getters,
};

export default products;
