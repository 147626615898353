import { Commit } from "vuex";
import { ProductsService } from "@/services";
import { types } from "./mutations";
import { ILoadProductsPayload } from "@/services/ProductsService/ProductsServiceTypes";

export default {
  async loadProducts(
    { commit }: { commit: Commit },
    payload: ILoadProductsPayload
  ) {
    commit(types.SET_PRODUCTS_FETCHING, true);
    try {
      const res = await ProductsService.loadProducts(payload);
      const { data, meta } = res.data;
      commit(types.ON_LOAD_PRODUCTS_SUCCESS, {
        list: data,
        pagination: meta,
      });
    } catch (e) {
      commit(types.ON_LOAD_PRODUCTS_ERROR, e);
    }
    commit(types.SET_PRODUCTS_FETCHING, false);
  },
  async loadMoreProducts(
    { commit }: { commit: Commit },
    payload: ILoadProductsPayload
  ) {
    commit(types.SET_PRODUCTS_FETCHING_MORE, true);
    try {
      const res = await ProductsService.loadProducts(payload);
      const { data, meta } = res.data;
      commit(types.ON_LOAD_MORE_PRODUCTS_SUCCESS, {
        list: data,
        pagination: meta,
      });
    } catch (e) {
      commit(types.ON_LOAD_MORE_PRODUCTS_ERROR, e);
    }
    commit(types.SET_PRODUCTS_FETCHING_MORE, false);
  },

  setNumberCategory({ commit }: { commit: Commit }, category: string) {
    commit(types.SET_CATEGORY, category);
  },
};
