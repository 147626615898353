import { Commit } from "vuex";
import { CategoriesService } from "@/services";
import { types } from "./mutations";
import { IPaginationPayload } from "@/common/types/pagination";

export default {
  async loadCategories(
    { commit }: { commit: Commit },
    payload: IPaginationPayload
  ) {
    commit(types.SET_CATEGORIES_FETCHING, true);
    try {
      const res = await CategoriesService.loadCategories(payload);
      const { data, meta } = res.data;
      commit(types.ON_LOAD_CATEGORIES_SUCCESS, {
        list: data,
        pagination: meta,
      });
    } catch (e) {
      commit(types.ON_LOAD_CATEGORIES_ERROR, e);
    }
    commit(types.SET_CATEGORIES_FETCHING, false);
  },
  async loadMoreCategories(
    { commit }: { commit: Commit },
    payload: IPaginationPayload
  ) {
    commit(types.SET_CATEGORIES_FETCHING, true);
    try {
      const res = await CategoriesService.loadCategories(payload);
      const { data, meta } = res.data;
      commit(types.ON_LOAD_MORE_CATEGORIES_SUCCESS, {
        list: data,
        pagination: meta,
      });
    } catch (e) {
      commit(types.ON_LOAD_MORE_CATEGORIES_ERROR, e);
    }
    commit(types.SET_CATEGORIES_FETCHING, false);
  },
};
