import { Commit } from "vuex";
import { OrderService } from "@/services";
import { types } from "./mutations";
import { ICreateOrderPayload } from "@/services/OrderService/OrderServiceTypes";
import telegram from "@/common/telegram";

export default {
  async createOrder(
    { commit }: { commit: Commit },
    payload: ICreateOrderPayload
  ) {
    commit(types.SET_CREATE_ORDER_FETCHING, true);
    try {
      const res = await OrderService.createOrder(payload);
      const tg = telegram.Instance;
      tg.context.close();
    } catch (e) {
      commit(types.ON_CREATE_ORDER_ERROR, e);
    }
    commit(types.SET_CREATE_ORDER_FETCHING, false);
  },
};
