import axios, { AxiosInstance } from "axios";

interface IHttp {
  get(resource: string, paramsData?: any): void; // отправить get-запрос
  post(resource: string, apiData: any): void; // отправить post-запрос
  delete(resource: string): void;
  put(resource: string, params: any): void;
}

interface IHttpClientOptions {
  _baseURL?: string;
  _headers?: Record<string, string> | undefined;
}

/**
 * @class - базовый класс для работы с сервером
 * @implements IHttp
 */
export default class $HttpClient implements IHttp {
  instance: AxiosInstance;

  constructor(options?: IHttpClientOptions) {
    this.instance = axios.create({
      baseURL: options?._baseURL,
      headers: options?._headers,
    });
  }

  async get(resource: string, params?: object): Promise<any> {
    return await this.instance.get(resource, { params });
  }

  async post(resource: string, data?: object, params?: object): Promise<any> {
    return await this.instance.post(resource, data, { params });
  }

  async delete(resource: string): Promise<any> {
    return await this.instance.delete(resource);
  }

  async put(resource: string, data: object): Promise<any> {
    return await this.instance.put(resource, data);
  }
}
