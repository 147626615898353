import { IProductData, IProductDataPayload, IState } from "./state";
import { IError } from "@/common/types/errors";

export const types = {
  ON_LOAD_PRODUCT_SUCCESS: "ON_LOAD_PRODUCT_SUCCESS",
  ON_LOAD_PRODUCT_ERROR: "ON_LOAD_PRODUCT_ERROR",
  SET_PRODUCT_FETCHING: "SET_PRODUCT_FETCHING",
};

export default {
  [types.ON_LOAD_PRODUCT_SUCCESS](state: IState, payload: IProductDataPayload) {
    const { loadFromSearch, data } = payload;
    state.productData = data;
    state.loadFromSearch = loadFromSearch;
  },
  [types.ON_LOAD_PRODUCT_ERROR](state: IState, errorsList: IError) {
    state.productErrors = errorsList;
  },
  [types.SET_PRODUCT_FETCHING](state: IState, isFetching: boolean) {
    state.isProductFetching = isFetching;
  },
};
