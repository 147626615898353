
import Vue, { PropType } from "vue";

export default Vue.extend({
  name: "UiModal",
  props: {
    isShowModal: {
      type: Boolean,
      required: true,
    },
    headerText: {
      type: String,
      required: true,
    },
  },
  methods: {
    toggleModal(): void {
      this.$emit("toggleModal");
    },
  },
});
