
import Vue from "vue";
import UiModal from "@/ui/ui-modal/UiModal.vue";
import UiIcon from "@/ui/ui-icon/UiIcon.vue";
import { mapGetters } from "vuex";
import { getOrderedSizes } from "@/utils";
import { ICartItem } from "@/store/cart/state";

export default Vue.extend({
  name: "ModalAddToCart",
  components: {
    UiModal,
    UiIcon,
  },
  props: {
    isShowModalSizes: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return { selectedColorIndex: 0, selectedSizeIndex: 0 };
  },
  computed: {
    ...mapGetters({
      productData: "productData",
      parsedProductData: "parsedProductData",
      cartIdsItemsList: "cartIdsItemsList",
      cartIdsProductList: "cartIdsProductList",
      cartList: "cartList",
    }),
    sizeList() {
      return (
        this.parsedProductData[this.selectedColor?.color?.value] || []
      ).map((item) => {
        return {
          ...item,
          size: {
            ...item.size,
            counter: 0,
          },
        };
      });
    },
    sortedSizeList() {
      return getOrderedSizes([...this.sizeList]);
    },
    colorList() {
      return Object.keys(this.parsedProductData).map(
        (item) => this.parsedProductData[item][0]
      );
    },
    selectedColor() {
      return this.colorList[this.selectedColorIndex] || {};
    },
    selectedSize() {
      return this.sizeList[this.selectedSizeIndex];
    },
  },
  methods: {
    toggleModal(): void {
      this.$emit("toggleModal");
    },
    checkProductInCart(id: number) {
      return !!this.cartIdsItemsList.find((cartId: number) => cartId === id);
    },
    getAvailableAmount(id: number) {
      return this.productData.ModificationsProduct.find(
        (product) => product.id === id
      ).stock;
    },
    getProductCountInCart(id: number) {
      return (
        this.cartList.find(
          (cart: ICartItem) => cart.ModificationsProductID === id
        ).count || 0
      );
    },
    onSizeClick(index: number, id: number) {
      this.selectedSizeIndex = index;
      if (id) {
        this.onAddClick(id, 0);
      }
    },
    onColorClick(index: number) {
      this.selectedColorIndex = index;
    },
    onAddClick(id: number, count: number) {
      const available = this.getAvailableAmount(id);
      if (available === count) {
        this.$toastr.warning(`К заказу доступно только ${available}шт`);
      } else {
        this.$store.dispatch("updateCart", { id, count: count + 1 });
      }
    },
    onRemoveClick(id: number, count: number) {
      const newCount = count - 1;
      if (newCount === 0) {
        const cartItemID = this.cartList.find(
          (item: ICartItem) => item.ModificationsProductID === id
        ).id;
        this.$store.dispatch("removeItemInCart", cartItemID);
      } else {
        this.$store.dispatch("updateCart", { id, count: newCount });
      }
    },
    checkCurrentProductInCart(productId: number) {
      return !!this.cartIdsProductList.find(
        (cartId: number) => cartId === productId
      );
    },
  },
});
