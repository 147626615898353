export interface IAddressProfile {
  phone: string;
  postalCode: number;
  town: string;
  address: string;
  name: string;
  surname: string;
  patronymic: string;
  id: number;
}

export interface IProfile {
  id: number;
  name: string;
  surname: string;
  stockID: null;
  telegramID: string;
  telegramName: string;
  createdAt: string;
  AddressProfile: Array<IAddressProfile>;
}

export interface IState {
  profileData: IProfile;
  isProfileFetching: boolean;
  isAddressProfileFetching: boolean;
  profileErrors: object;
  addressProfileErrors: object;
}
export default (): IState => ({
  profileData: <IProfile>{},
  isProfileFetching: false,
  isAddressProfileFetching: false,
  profileErrors: {},
  addressProfileErrors: {},
});
