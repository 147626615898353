import Vue from "vue";

/**
 * @description - Директива для копирования текста
 * Директива не привязана к элементам и работает по директиве v-clipboard, поэтому при использование библиотеке можно
 * ничего не менять. Директива работает при обновление значения для копирования. При разрушения директивы снимаются
 * события клика/эвентов срабатывания при удачном копирование/наличия ошибки.
 * Уведомление о ошибках или удачном копировании привязываются к элементу путем создания переменной в объекте элемента
 *
 * Элементы директивы:
 * @param el          - Элемент полученный директивой
 * @param bind        - Параметры при привязке директивы
 * @param vn          - Контекст
 */
Vue.directive("clipboard", {
  bind: (el, bind, vn) => {
    vn.context.$nextTick(() => {
      if (bind.arg === "success") {
        el._vClipboard_success = bind.value;
      } else if (bind.arg === "error") {
        el._vClipboard_error = bind.value;
      } else if (bind.arg === "copy") {
        el._vClipboard_text = bind.value;
        el.addEventListener("click", () => $copy(bind, el));
        el.addEventListener("keypress", (e) => {
          if (e.key === "Enter" && el.tagName !== "BUTTON") {
            $copy(bind, el);
          }
        });
      }
    });
  },
  unbind: (el, bind) => {
    if (bind.arg === "success") {
      delete el._vClipboard_success;
    } else if (bind.arg === "error") {
      delete el._vClipboard_error;
    } else if (bind.arg === "copy") {
      delete el._vClipboard_text;
      el.removeEventListener("click", () => $copy(bind, el));
      el.removeEventListener("keypress", (e) => {
        if (e.key === "Enter" && el.tagName !== "BUTTON") {
          $copy(bind, el);
        }
      });
    }
  },
  update: (el, bind, vn) => {
    vn.context.$nextTick(() => {
      if (bind.arg === "success") {
        el._vClipboard_success = bind.value;
      } else if (bind.arg === "error") {
        el._vClipboard_error = bind.value;
      } else if (bind.arg === "copy") {
        el._vClipboard_text = bind.value;
      }
    });
  },
});

/**
 * @description - Отдельная функция с промисом для возможности отвязки события от элемента
 * @param bind  - Параметры при привязке директивы
 * @param el    - Элемент полученный директивой
 */
const $copy = (bind, el) => {
  copy(el._vClipboard_text)
    .then(() => {
      el._vClipboard_success ? el._vClipboard_success() : console.log("copied");
    })
    .catch(() => {
      el._vClipboard_error ? el._vClipboard_error() : console.log("ERROR");
    });
};

/**
 * @description               - функция копировать текста путем создания инпута с копированием его value
 *                              У инпута убирается эвент фокуса для возможности селекта + копирования вне документа
 * @param textToCopy                - текст для копирования
 * @return {Promise<Boolean>} -
 */
function copy(textToCopy) {
  return new Promise((resolve, reject) => {
    let textArea, result;

    /**
     * @description               - Является ли устройство IOS
     * @return {RegExpMatchArray}
     */
    function isOS() {
      return navigator.userAgent.match(/ipad|iphone/i);
    }

    function createTextArea(text) {
      textArea = document.createElement("textArea");
      textArea.readOnly = true;
      textArea.contentEditable = true;
      textArea.value = text;
      document.body.appendChild(textArea);
    }

    function selectText() {
      let range, selection;

      if (isOS()) {
        range = document.createRange();
        range.selectNodeContents(textArea);
        selection = window.getSelection();
        selection.removeAllRanges();
        selection.addRange(range);
        textArea.setSelectionRange(0, 999999);
      } else {
        textArea.addEventListener("focusin", (e) => {
          e.stopPropagation();
          e.preventDefault();
        });
        textArea.select();
        textArea.focus();
      }
    }

    function copyTo() {
      result = document.execCommand("copy");
      document.body.removeChild(textArea);
    }

    createTextArea(textToCopy);
    selectText();
    copyTo();

    result ? resolve(true) : reject(false);
  });
}
