import { IModificationsProduct } from "@/store/product/state";

export interface ICartItem {
  ModificationsProduct: IModificationsProduct;
  ModificationsProductID: number;
  cartID: number;
  count: number;
  id: number;
}

export interface ICartData {
  CartItems: Array<ICartItem>;
  id: number;
  orderID: number | null;
  profileID: number;
}

export interface IState {
  cartData: ICartData;
  isCartFetching: boolean;
  cartErrors: object;
}
export default (): IState => ({
  cartData: <ICartData>{},
  isCartFetching: false,
  cartErrors: {},
});
