import { IState } from "./state";

export default {
  productsList: (state: IState) =>
    state.productsData?.list?.filter((item) => {
      return item?.ModificationsProduct?.length > 0;
    }),
  isProductsFetching: (state: IState) => state.isProductsFetching,
  isProductsFetchingMore: (state: IState) => state.isProductsFetchingMore,
  productsPagination: (state: IState) => state.productsData.pagination,
  numberCategory: (state: IState) => state.numberCategory,
};
