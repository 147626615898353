var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"shop-card",on:{"click":_vm.onItemClickLocal}},[_c('div',[_c('div',{staticClass:"shop-card__img-container"},[_c('img',{staticClass:"shop-card__img",attrs:{"src":_vm.getImage,"alt":`Товар: ${_vm.data.name}`}}),(_vm.data.saleType)?_c('div',{staticClass:"shop-card__status",class:_vm.data.saleType.toLowerCase()},[_vm._v(" "+_vm._s(_vm.data.saleType)+" ")]):_vm._e()]),_c('div',{staticClass:"shop-card__price-container"},[_c('p',{staticClass:"shop-card__price"},[_vm._v(" "+_vm._s(`${_vm.numberSeparator( _vm.data.priceAfterDiscount / 100 || _vm.data.price / 100 )} Р`)+" ")]),(_vm.data.priceAfterDiscount)?_c('p',{staticClass:"shop-card__price-old"},[_vm._v(" "+_vm._s(`${_vm.data.price / 100} Р`)+" ")]):_vm._e()]),_c('div',{staticClass:"shop-card__description"},[_vm._v(_vm._s(_vm.data.name))])]),_c('div',[_c('div',{staticClass:"shop-card__stars-container"}),_c('div',{staticClass:"shop-card__button-wrap"},[_c('button',{staticClass:"shop-card__button",class:{
          'in-basket-button': _vm.checkProductInCart(_vm.data.id),
        },on:{"click":function($event){return _vm.onButtonClickLocal($event)}}},[(_vm.checkProductInCart(_vm.data.id))?_c('span',{staticClass:"shop-card__button-text",class:{
            'in-basket-text': _vm.checkProductInCart(_vm.data.id),
          }},[_vm._v("В корзине")]):_c('span',{staticClass:"shop-card__button-text",class:{
            'in-basket-text': _vm.checkProductInCart(_vm.data.id),
          }},[_vm._v("В корзину")])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }