
import ProductsItem from "@/components/products/products-item/ProductsItem.vue";
import { mapGetters } from "vuex";
import Vue from "vue";
import ModalSizes from "@/components/modals/modal-sizes/ModalSizes.vue";
import telegram from "@/common/telegram";
import { Constants } from "@/config";
import UiSpinner from "@/ui/ui-spinner";
export default Vue.extend({
  components: {
    ProductsItem,
    ModalSizes,
    UiSpinner,
  },
  data() {
    let isShowModalSizes = false;
    return { isShowModalSizes };
  },
  computed: {
    ...mapGetters({
      productsList: "productsList",
      productsPagination: "productsPagination",
      isProductsFetching: "isProductsFetching",
      isProductsFetchingMore: "isProductsFetchingMore",
      numberCategory: "numberCategory",
    }),
  },
  created() {
    const categoryId = this.$route.query.category;
    if (!categoryId) {
      this.$router.push("/categories");
    } else if (categoryId !== this.numberCategory) {
      this.setNumberCategory(categoryId);
      this.loadProducts(+categoryId);
    } else {
      if (!this.productsList?.length) {
        this.setNumberCategory(categoryId);
        this.loadProducts(+categoryId);
      }
    }
  },
  mounted() {
    window.addEventListener("scroll", this.scrollHandler);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.scrollHandler);
  },
  methods: {
    setNumberCategory(categoryNumber: string) {
      this.$store.dispatch("setNumberCategory", categoryNumber);
    },
    loadProducts(id: number) {
      const payload = {
        limit: Constants.BASE_API_LIMIT,
        offset: 1,
        id,
      };
      this.$store.dispatch("loadProducts", payload);
    },
    loadMoreProducts(id: number) {
      const payload = {
        limit: this.productsPagination?.limit,
        offset: this.productsPagination?.nextOffset,
        id,
      };
      if (this.productsPagination?.nextOffset) {
        this.$store.dispatch("loadMoreProducts", payload);
      }
    },
    scrollHandler() {
      const watchContainer = document.querySelector(".shop-grid");
      const containerHeight = (watchContainer as any).offsetHeight;
      const isBottom =
        document.documentElement.scrollTop + window.innerHeight >=
        containerHeight;
      if (
        isBottom &&
        !this.isProductsFetching &&
        !this.isProductsFetchingMore
      ) {
        const categoryId = this.$route.query.category;
        this.loadMoreProducts(+categoryId);
      }
    },
    onItemClick(vendorCode: number) {
      if (vendorCode) {
        this.$router.push(`/product?vendorCode=${vendorCode}`);
      }
    },
    toggleModal(vendorCode: number) {
      if (this.isShowModalSizes === false) {
        const tg = telegram.Instance;
        if (!tg?.context.isExpanded) {
          tg?.context.expand();
        }
        this.$store.dispatch("loadOneProduct", {
          vendorCode: vendorCode,
          loadFromSearch: false,
        });
      }
      this.isShowModalSizes = !this.isShowModalSizes;
    },
  },
});
