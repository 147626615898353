import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import CategoriesView from "../views/categories/CategoriesView.vue";
import ProductsView from "../views/products/ProductsView.vue";
import ProductView from "../views/product/ProductView.vue";
import BasketView from "../views/basket/BasketView.vue";
import InstructionView from "../views/instruction/InstructionView.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "home",
    redirect: () => "/categories",
  },
  {
    path: "/categories",
    name: "categories",
    component: CategoriesView,
  },
  {
    path: "/products",
    name: "products",
    component: ProductsView,
  },
  {
    path: "/product",
    name: "product",
    component: ProductView,
  },
  {
    path: "/cart",
    name: "cart",
    component: BasketView,
  },
  {
    path: "/instruction",
    name: "instruction",
    component: InstructionView,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
