import { IPagination } from "@/common/types/pagination";
import { Constants } from "@/config";
import { IModificationsProduct } from "@/store/product/state";

export interface IImage {
  id: number;
  link: string;
}

export interface IProductImage {
  id: number;
  productID: number;
  imageID: number;
  Image: IImage;
}

export interface IProduct {
  id: number;
  name: string;
  description: string;
  categoryID: string;
  postalCode: string;
  stockID: string;
  isView: boolean;
  price: number;
  ProductImage: [IProductImage];
  ModificationsProduct: Array<IModificationsProduct>;
  saleType: "SALE" | "HIT";
  priceAfterDiscount: number;
  vendorCode: string;
}

export interface IProductsData {
  list: Array<IProduct>;
  pagination: IPagination;
}

export interface IState {
  productsData: IProductsData;
  isProductsFetching: boolean;
  isProductsFetchingMore: boolean;
  productsErrors: object;
  numberCategory: string;
}
export default (): IState => ({
  productsData: <IProductsData>{
    list: [],
    pagination: <IPagination>{
      limit: Constants.BASE_API_LIMIT,
      nextOffset: 1,
    },
  },
  isProductsFetching: false,
  isProductsFetchingMore: false,
  productsErrors: {},
  numberCategory: "",
});
